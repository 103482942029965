import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import './GamePage.css';
import WordleGrid from "./WordleGrid";

/**
 * Game component contains the wordle game with buttons to quit and submit
 */
function GamePage({ mode }) {
    return (
        <div className="container">
            <AppBar position='static' sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'darkgreen', justifyContent: 'space-between', alignItems: 'center', }}>
                <Typography variant='h4'>
                    Word Game Page
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h7" id='user'>
                        <Link to="/">Log In</Link>
                    </Typography>
                </Box>
            </AppBar>
            <Box component="div" sx={{
                border: 3, borderColor: 'darkgreen', borderRadius: 3, p: 2, margin: '5%', marginRight: '25%', backgroundColor: 'white', display: 'flex',
                flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                marginLeft: '25%',
            }}>
                <Accordion>
                    <AccordionSummary>
                        <Typography>
                            How to Play*
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Enter your guess in the input box
                        </Typography>
                        <Typography>
                            Select Submit Guess to test your answer
                        </Typography>
                        <Typography>
                            If you get it right all tiles will turn green
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <h1>
                    {mode === "freeplay" ? "FreePlay Mode" : "What's the Howl?"}
                </h1>
                <div className="game-section">
                    <Box sx={{ backgroundColor: 'black', padding: 2, borderRadius: 2 }}>
                        {/* Wordle Game Section */}
                        <WordleGrid />
                    </Box>
                </div>
                <div id="finish_buttons">
                    <Link to="/" style={{ textDecoration: 'none', marginRight: 8 }}>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: 4, backgroundColor: '#FFD700', color: 'black' }}
                        >
                            Quit
                        </Button>
                    </Link>
                    <Link to="/statistics" style={{ textDecoration: 'none' }}>
                        <Button variant="contained" sx={{ borderRadius: 4, backgroundColor: '#FFD700', color: 'black' }} >
                            Submit
                        </Button>
                    </Link>
                </div>
            </Box>
        </div>
    );
}

export default GamePage;