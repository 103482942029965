import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardActions, TextField, Box, CardHeader } from "@mui/material";
import "./WelcomePage.css";

function WelcomePage() {
    return (
        <div id="body">
            <Box id="Box" align="center" component="section" sx={{ p: 2 }}>
                <Card align="center" variant="outlined" id="Card" sx={{ width: "27%", borderRadius: 2 }}>
                    <CardContent id="CardContent">
                        <CardHeader
                            title="NC State Wordle"
                            titleTypographyProps={{
                                fontFamily: 'Segoe UI, Tahoma, sans-serif',
                                fontSize: 30,
                                fontWeight: 'bold',
                            }}
                        />
                        <TextField
                            id="username"
                            required
                            label="Username"
                            type="text"
                            name="user"
                            sx={{
                                fontFamily: 'Tahoma, sans-serif',
                                borderRadius: 2,
                                backgroundColor: "lightgrey",
                                color: 'black',
                            }}
                        />
                        <TextField
                            id="password"
                            required
                            label="Password"
                            type="password"
                            name="password"
                            sx={{
                                fontFamily: 'Tahoma, sans-serif',
                                fontWeight: 200,
                                borderRadius: 2,
                                backgroundColor: "lightgrey",
                                color: 'black',
                            }}
                        />
                    </CardContent>
                    <CardActions>
                        <Link to="/register" style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: 'Tahoma, sans-serif',
                                    borderRadius: 4,
                                    backgroundColor: '#FFD700',
                                    color: 'black',
                                }}
                            >
                                Register
                            </Button>
                        </Link>
                        <Link to="/game" style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: 'Tahoma, sans-serif',
                                    borderRadius: 4,
                                    backgroundColor: '#FFD700',
                                    color: 'black',
                                }}
                            >
                                Login
                            </Button>
                        </Link>
                    </CardActions>
                    <CardActions>
                        <Link to="/game" style={{ textDecoration: 'none' }}>
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: 'Tahoma, sans-serif',
                                    borderRadius: 4,
                                    backgroundColor: 'darkgreen',
                                    color: 'white',
                                }}
                            >
                                Free Play
                            </Button>
                        </Link>
                    </CardActions>
                </Card>
            </Box>
        </div>
    );
}

export default WelcomePage;