import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function HistoryPage() {
    return (
        <div className="container">
            <AppBar position='static' sx={{display:'flex', flexDirection:'row', backgroundColor:'darkgreen', justifyContent: 'space-between', alignItems: 'center',}}>

                <Typography variant='h4'>
                    History Page
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h7" id='user'>
                        <Link to="/">Log In</Link>
                    </Typography>
                </Box>
            </AppBar>
            <div className="center-content">
                <p>Game history will be displayed here.</p>
            </div>
            <div className="bottom-buttons">
                <Link to="/game" style={{ textDecoration: 'none', marginRight: 8 }}>
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: 'Tahoma, sans-serif',
                            borderRadius: 4,
                            backgroundColor: 'darkgreen',
                            color: 'white',
                        }}
                    >
                        Free Play
                    </Button>
                </Link>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 4, backgroundColor: '#FFD700', color: 'black' }}
                    >
                        Quit
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default HistoryPage;