import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GamePage from "./components/GamePage";
import HistoryPage from "./components/HistoryPage";
import RegisterPage from "./components/RegisterPage";
import StatisticsPage from "./components/StatisticsPage";
import WelcomePage from "./components/WelcomePage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/game" element={<GamePage mode="normal" />} />
                <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/history" element={<HistoryPage />} />
                <Route path="*" element={<WelcomePage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;