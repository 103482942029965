import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "./WordleGrid.css";
import wordList from '../data/wordList.json';

// magic variables (constants)
const MAX_GUESSES = 5; // number of guesses allowed
const WORD_LENGTH = 5; // length of the word
//const TARGET_WORD = "WOOOF"; // the target word (replace in production with a random word)

// generate word for the game
const getRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * wordList.length);
    return wordList[randomIndex];
};

const TARGET_WORD = getRandomWord();
console.log("target word is ", TARGET_WORD);

function sendGame(attempts) {
    // HTTPClient.post()
}

// component to render a single row of the Wordle grid
function GridRow({ guess, status }) {
    return (
        <div className="wordle-row">
            {Array.from({ length: WORD_LENGTH }).map((_, colIndex) => (
                <div
                    className={`wordle-cell ${status[colIndex] || ""}`}
                    key={colIndex}
                >
                    {guess[colIndex] || ""}
                </div>
            ))}
        </div>
    );
}

// component for the input section where users type their guesses
function InputSection({ guess, onInputChange, onSubmit }) {
    return (
        <div className="input-section">
            <TextField
                type="text"
                value={guess}
                onChange={onInputChange}
                maxLength={WORD_LENGTH} id="filled-basic" variant="standard"
                autoFocus sx={{backgroundColor: 'white', borderRadius: 4}}
            />
            <Button variant="contained" sx={{ borderRadius: 4, backgroundColor:"darkgreen"}} onClick={onSubmit}>Submit Guess</Button>
        </div>
    );
}

function WordleGrid() {
    // state variables
    const [guesses, setGuesses] = useState(Array(MAX_GUESSES).fill("")); // tracks all guesses
    const [statuses, setStatuses] = useState(Array(MAX_GUESSES).fill([])); // tracks status of each guess(correct, present, absent)
    const [currentGuessIndex, setCurrentGuessIndex] = useState(0); // tracks current guess index
    const [gameOver, setGameOver] = useState(false); // flag for game over state

    // handle input changes for the current guess
    // make sure input is valid
    // converts input to upper case
    // current guess is updated in the guesses array
    const handleInputChange = (event) => {
        const value = event.target.value.toUpperCase();

        // ensure the input is valid and no longer than WORD_LENGTH characters
        if (new RegExp(`^[A-Z]{0,${WORD_LENGTH}}$`).test(value)) {
            const newGuesses = [...guesses];
            newGuesses[currentGuessIndex] = value;
            setGuesses(newGuesses);
        }
    };

    // handle the submission of the current guess
    const handleSubmit = () => {
        const guess = guesses[currentGuessIndex];

        if (guess.length !== WORD_LENGTH) {
            alert(`Please enter a ${WORD_LENGTH}-letter word.`);
            return;
        }
        checkGuess(guess);
    };

    // check the submitted guess and update statuses
    const checkGuess = (guess) => {
        const result = [];
        const targetLetters = TARGET_WORD.split("");
        const guessLetters = guess.split("");

        // iterate each letter of the guess and compare it with target
        for (let i = 0; i < WORD_LENGTH; i++) {
            if (guessLetters[i] === targetLetters[i]) {
                result.push("correct"); // letter is correct and in the right place
            } else if (targetLetters.includes(guessLetters[i])) {
                result.push("present"); // letter is in the word but in the wrong position
            } else {
                result.push("absent"); // letter is not in the word
            }
        }

        // update statuses state with result
        const newStatuses = [...statuses];
        newStatuses[currentGuessIndex] = result;
        setStatuses(newStatuses);

        // check if the game is won or lost
        if (guess === TARGET_WORD) {
            setGameOver(true);
            sendGame(currentGuessIndex);
            alert("Congrats! you've got the word!");
        } else if (currentGuessIndex >= MAX_GUESSES - 1) {
            setGameOver(true);
            alert(`Game Over! The word was ${TARGET_WORD}`);
        } else {
            setCurrentGuessIndex(currentGuessIndex + 1); // move to the next guess
        }
    };

    return (
        <div className="wordle-grid">
            {/* render the grid of guesses */}
            {guesses.map((guess, rowIndex) => (
                <GridRow
                    key={rowIndex}
                    guess={guess}
                    status={statuses[rowIndex]}
                />
            ))}

            {/* render the input section only if the game is not over and there are remaining guesses */}
            {!gameOver && currentGuessIndex < MAX_GUESSES && (
                <InputSection
                    guess={guesses[currentGuessIndex]}
                    onInputChange={handleInputChange}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
}

export default WordleGrid;
