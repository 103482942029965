import { Box, Button, Card, CardActions, CardContent, CardHeader, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./RegisterPage.css";

function RegisterPage() {
    const [values, setValues] = useState({
        name: '',
        password: ''
    });

    const navigate = useNavigate(); // To redirect after registration

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/'); // Redirect to login page
    };

    return (
        <div className="container">
            <Box id="Box" align="center" component="section" sx={{ p: 2 }}>
                <Card align="center" variant="outlined" id="Card" sx={{ width: "27%", borderRadius: 2 }}>
                    <CardContent id="CardContent">
                        <CardHeader
                            title="Register"
                            titleTypographyProps={{
                                fontFamily: 'Arial, sans-serif',
                                fontSize: 30,
                                fontWeight: 'bold',
                            }}
                        />
                        <form onSubmit={handleSubmit}>
                            <TextField
                                required
                                label="Username"
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                sx={{ marginBottom: 2 }}
                            />
                            <TextField
                                required
                                label="Password"
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                sx={{ marginBottom: 2 }}
                            />
                            <CardActions align="right">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ p: 2, backgroundColor: '#FFD700', color: 'black' }}
                                >
                                    Create
                                </Button>
                            </CardActions>
                        </form>
                    </CardContent>
                    <Link to="/" className="link">Back to login</Link>
                </Card>
            </Box>
        </div>
    );
}

export default RegisterPage;