import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function StatisticsPage() {
    return (
        <div className="container">
            <AppBar
                position='static'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: 'darkgreen',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant='h4'>
                    Statistics Page
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h7" id='user'>
                        <Link to="/">Log In</Link>
                    </Typography>
                </Box>
            </AppBar>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 1 }}>
                <div id="streak">Your streak count: </div>
                <div id="longest">Your longest streak: </div>
                <div id="highest">Your highest score: </div>
            </Box>
            <Box className="three-button-group" sx={{ margin: 2 }}>
                <Link to="/history" style={{ textDecoration: 'none', marginRight: 8 }}>
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: 'Tahoma, sans-serif',
                            borderRadius: 4,
                            backgroundColor: 'darkgreen',
                            color: 'white',
                        }}
                    >
                        History
                    </Button>
                </Link>
                <Link to="/game" style={{ textDecoration: 'none', marginRight: 8 }}>
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: 'Tahoma, sans-serif',
                            borderRadius: 4,
                            backgroundColor: 'darkgreen',
                            color: 'white',
                        }}
                    >
                        Free Play
                    </Button>
                </Link>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 4, backgroundColor: '#FFD700', color: 'black' }}
                    >
                        Quit
                    </Button>
                </Link>
            </Box>
        </div>
    );
}

export default StatisticsPage;
